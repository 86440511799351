import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const LeftSidebar = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const history = useHistory();

  const [position, setPosition] = useState(
    useMemo(() => {
      const position = localStorage.getItem("position");
      if (position) {
        return position;
      }
      return "";
    }, [])
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // scroll to top when changing page
  useEffect(() => {
    let element = document.getElementById("sidebarContent");
    element.scrollTo(0, localStorage.getItem("position"));
  }, [position]);

  localStorage.setItem("position", position);

  const sidebarData = [
    {
      title: "Dashboard",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
        "ROLE_HR",
      ],
      subtitle: [
        {
          url: "/admin",
          name: "Dashboard",
          icon: "fas fa-tachometer-alt",
          auth: [],
        },
      ],
    },
    {
      title: "Personal Settings",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
        "ROLE_HR",
      ],
      subtitle: [
        {
          url: "/admin/profile-settings",
          name: "Settings and Privacy",
          icon: "fas fa-cogs",
          auth: [],
        },
        {
          url: "/admin/profile",
          name: "Profile",
          icon: "far fa-user-circle",
          auth: [],
        },
      ],
    },
    {
      title: "Export Reports",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
      ],
      subtitle: [
        {
          name: "Reports",
          auth: [
            "ROLE_CEO",
            "ROLE_MANAGER",
            "ROLE_RECEPTIONIST",
            "ROLE_MARKETING_MANAGER",
          ],
          icon: "fas fa-file-excel-o",
          dropdown: true,
          dropdownData: [
            { url: "/admin/report/product", type: "Product Report", display: [] },
            { url: "/admin/report/order", type: "Order Report", display: [] },
          ],
        },
      ],
    },
    {
      title: "User Management",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
      ],
      subtitle: [
        {
          name: "Users",
          auth: [
            "ROLE_CEO",
            "ROLE_MANAGER",
            "ROLE_RECEPTIONIST",
            "ROLE_MARKETING_MANAGER",
          ],
          icon: "fas fa-users-cog",
          dropdown: true,
          dropdownData: [
            { url: "/admin/details", type: "Admin", display: ["ROLE_CEO"] },
            { url: "/admin/retailers", type: "Retailer", display: [] },
            { url: "/admin/wholesalers", type: "Wholesaler", display: [] },
            {
              url: "/admin/customers",
              type: "Customer",
              display: ["ROLE_CEO", "ROLE_RECEPTIONIST"],
            },
          ],
        },
      ],
    },
    {
      title: "Orders Related",
      showFor: ["ROLE_CEO", "ROLE_MANAGER"],
      subtitle: [
        {
          name: "Quotations",
          auth: ["ROLE_CEO"],
          icon: "fab fa-monero",
          dropdown: true,
          dropdownData: [
            { url: "/admin/quotations/retailer", type: "Retailer" },
            { url: "/admin/quotations/wholesaler", type: "Wholesaler" },
            { url: "/admin/quotations/user", type: "User" },
            { url: "/admin/quotations/assigned", type: "Assigned" },
          ],
        },
        {
          name: "Custom Order",
          icon: "fas fa-boxes",
          auth: [],
          dropdown: true,
          dropdownData: [
            { url: "/admin/custom-order/image", type: "Image" },
            { url: "/admin/custom-order/audio", type: "Audio" },
          ],
        },
        {
          name: "Normal Orders",
          icon: "fas fa-cart-arrow-down",
          auth: [],
          dropdown: true,
          dropdownData: [
            { url: "/admin/order/view/retail", type: "Retailer" },
            { url: "/admin/order/view/wholesale", type: "Wholesaler" },
            { url: "/admin/order/return", type: "Returned" },
            { url: "/admin/order/assigned", type: "Assigned Delivery" },
          ],
        },
        {
          name: "Discount Voucher",
          icon: "fas fa-percent",
          auth: [],
          url: "/admin/discount-voucher",
        },
      ],
    },
    {
      title: "Tree Plantation",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
        "ROLE_HR",
      ],
      subtitle: [
        {
          name: "Tree Implementation",
          icon: "fas fa-tree",
          auth: [],
          dropdown: true,
          dropdownData: [
            { url: "/admin/tree-limit/view", type: "Tree Limit" },
            {
              url: "/admin/tree-implemented",
              type: "Implemented Tree",
            },
            { url: "/admin/tree-unimplemented", type: "Unimplemented Tree" },
          ],
        },
      ],
    },
    {
      title: "HR Module",
      showFor: [
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_RECEPTIONIST",
        "ROLE_MARKETING_MANAGER",
        "ROLE_HR",
      ],
      subtitle: [
        {
          name: "Reviews",
          icon: "fas fa-comments",
          auth: ["ROLE_CEO"],
          url: "/admin/view/reviews",
        },
        {
          name: "Send Notification",
          icon: "fas fa-bell",
          auth: ["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"],
          url: "/admin/notification",
        },
        {
          name: "Complaint",
          icon: "fas fa-comment-slash",
          auth: ["ROLE_CEO"],
          url: "/admin/complaint",
        },
        {
          name: "Enquiry",
          icon: "fas fa-phone",
          auth: [],
          url: "/admin/enquiry",
        },
        {
          name: "Messages",
          icon: "fas fa-sms",
          auth: [],
          url: "/admin/messages",
        },
        {
          name: "Vacancies",
          icon: "fa fa-tasks",
          auth: ["ROLE_CEO", "ROLE_HR"],
          dropdown: true,
          dropdownData: [
            { url: "/admin/vacancies", type: "Active" },
            { url: "/admin/applied-vacancies", type: "Applied" },
          ],
        },
      ],
    },
    {
      title: "Address Services",
      showFor: ["ROLE_CEO"],
      subtitle: [
        {
          url: "/admin/country",
          name: "Country",
          icon: "fas fa-globe",
          auth: [],
        },
        {
          url: "/admin/province",
          name: "Province",
          icon: "fas fa-map",
          auth: [],
        },
        {
          url: "/admin/district",
          name: "District",
          icon: "fas fa-location-arrow",
          auth: [],
        },
      ],
    },
    {
      title: "Site Settings",
      showFor: ["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"],
      subtitle: [
        {
          name: "Banner",
          icon: "fas fa-images",
          auth: [],
          dropdown: true,
          dropdownData: [
            { url: "/admin/view/seller-banner", type: "Seller" },
            { url: "/admin/view/user-banner", type: "Customer" },
          ],
        },
        {
          url: "/admin/view/faq",
          name: "FAQ",
          icon: "fa fa-question-circle",
          auth: ["ROLE_CEO", "ROLE_HR"],
        },
        {
          url: "/admin/news-blog-notice",
          name: "Blogs",
          icon: "fab fa-blogger-b",
          auth: ["ROLE_CEO", "ROLE_HR"],
        },
        {
          url: "/admin/legal-docs",
          name: "Legal Docs",
          icon: "fas fa-file",
          auth: [],
        },
      ],
    },
    {
      title: "Tools & Components",
      showFor: ["ROLE_CEO", "ROLE_MANAGER"],
      subtitle: [
        {
          url: "/company-type",
          name: "Company Type",
          icon: "fas fa-building",
          auth: [],
        },
        {
          name: "Category",
          icon: "fas fa-bezier-curve",
          auth: [],
          dropdown: true,
          dropdownData: [
            { url: "/admin/primary-category/view", type: "Primary" },

            {
              url: "/admin/secondary-category/view",
              type: "Secondary",
            },
            { url: "/admin/ternary-category/view", type: "Ternary" },
          ],
        },
      ],
    },
    {
      title: "Brand",
      showFor: ["ROLE_CEO", "ROLE_MANAGER"],
      subtitle: [
        {
          url: "/admin/brand",
          name: "Brand",
          icon: "fab fa-bandcamp",
          auth: [],
        },
      ],
    },
    {
      title: "Product Features",
      showFor: ["ROLE_CEO", "ROLE_MANAGER"],
      subtitle: [
        {
          url: "/admin/group-product/view",
          name: "Group Product",
          icon: "fas fa-object-group",
          auth: ["ROLE_CEO"],
        },
        {
          name: "Recent Product",
          auth: [],
          icon: "fab fa-product-hunt",
          dropdown: true,
          dropdownData: [
            { url: "/admin/product/view/retail", type: "Retailer" },
            { url: "/admin/product/view/wholesale", type: "Wholesaler" },
          ],
        },
        {
          name: "Flash Product",
          auth: [],
          icon: "fa fa-bolt",
          dropdown: true,
          dropdownData: [
            { url: "/admin/flash-product/view/retail", type: "Retailer" },
            {
              url: "/admin/flash-product/view/wholesale",
              type: "Wholesaler",
            },
          ],
        },
        {
          name: "Feature Product",
          auth: [],
          icon: "fa fa-star",
          dropdown: true,
          dropdownData: [
            { url: "/admin/feature-product/view/retail", type: "Retailer" },
            {
              url: "/admin/feature-product/view/wholesale",
              type: "Wholesaler",
            },
          ],
        },
        {
          name: "Trending Product",
          auth: [],
          icon: "fa fa-arrow-up",
          dropdown: true,
          dropdownData: [
            { url: "/admin/trending-product/view/retail", type: "Retailer" },
            {
              url: "/admin/trending-product/view/wholesale",
              type: "Wholesaler",
            },
          ],
        },
        {
          name: "Organic Product",
          auth: [],
          icon: "fa fa-leaf",
          dropdown: true,
          dropdownData: [
            { url: "/admin/organic-product/view/retail", type: "Retailer" },
            {
              url: "/admin/organic-product/view/wholesale",
              type: "Wholesaler",
            },
          ],
        },
        {
          name: "Kreesa Product",
          auth: [],
          icon: "fab fa-product-hunt",
          dropdown: true,
          dropdownData: [
            { url: "/admin/kreesa-product/view/retail", type: "Retailer" },
            {
              url: "/admin/kreesa-product/view/wholesale",
              type: "Wholesaler",
            },
          ],
        },
        // {
        //   name: "Brand Product",
        //   auth: [],
        //   icon: "fa fa-copyright",
        //   dropdown: true,
        //   dropdownData: [
        //     { url: "/admin/brand-product/view/retail", type: "Retailer" },
        //     {
        //       url: "/admin/brand-product/view/wholesale",
        //       type: "Wholesaler",
        //     },
        //   ],
        // },
      ],
    },
    {
      title: "Manage Bulk Product",
      showFor: ["ROLE_CEO", "ROLE_MANAGER"],
      subtitle: [
        {
          url: "/admin/bulk-product-register",
          name: "Register Bulk Product",
          icon: "fab fa-product-hunt",
          auth: [],
        },
      ],
    },
  ];
  return (
    <nav id="sidebar" className={`collapse ${matches && "show"}`}>
      <div
        className="sidebar-content js-simplebar"
        id="sidebarContent"
        style={{ width: 300 }}
        onScroll={(e) => setPosition(Math.round(e.target.scrollTop))}
      >
        <Link className="sidebar-brand" to="/admin">
          <span className="align-middle">Kreesa Dashboard</span>
        </Link>

        <ul className="sidebar-nav">
          {sidebarData.map((i) => (
            <>
              {i.showFor.includes(localStorage.getItem("auth")) && (
                <li className="sidebar-header">{i.title}</li>
              )}

              {(i.showFor.includes(localStorage.getItem("auth")) ||
                i.showFor.length === 0) &&
                i.subtitle.map(
                  (j) =>
                    (j.auth.includes(localStorage.getItem("auth")) ||
                      j.auth.length === 0) && (
                      <>
                        {j.dropdown ? (
                          <li className="sidebar-item">
                            <a
                              data-target={`#${j.name.replace(/\s/g, "-")}`}
                              data-toggle="collapse"
                              className="sidebar-link collapsed"
                            >
                              <i className={j.icon}></i>
                              <span className="align-middle">{j.name}</span>
                            </a>
                            <ul
                              id={j.name.replace(/\s/g, "-")}
                              className={`sidebar-dropdown list-unstyled ${
                                j.dropdownData.some(({ url }) =>
                                  history.location.pathname.includes(url)
                                )
                                  ? ""
                                  : "collapse"
                              }`}
                              data-parent="#sidebar"
                            >
                              {j.dropdownData.map((k) =>
                                k?.display ? (
                                  (k?.display?.includes(
                                    localStorage.getItem("auth")
                                  ) ||
                                    k?.display?.length === 0) && (
                                    <li className="sidebar-item">
                                      <NavLink
                                        className="sidebar-link"
                                        to={k.url}
                                        exact
                                        activeClassName="active_nav__admin_1"
                                      >
                                        {k.type}
                                      </NavLink>
                                    </li>
                                  )
                                ) : (
                                  <li className="sidebar-item">
                                    <NavLink
                                      className="sidebar-link"
                                      to={k.url}
                                      exact
                                      activeClassName="active_nav__admin_1"
                                    >
                                      {k.type}
                                    </NavLink>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        ) : (
                          <li className="sidebar-item">
                            <NavLink
                              className="sidebar-link"
                              exact
                              activeClassName="active_nav0"
                              to={j.url}
                            >
                              <i className={j.icon}></i>
                              <span className="align-middle">{j.name}</span>
                            </NavLink>
                          </li>
                        )}
                      </>
                    )
                )}
            </>
          ))}
          {/* blank div */}
          <li className="sidebar-header"></li>
          <li className="sidebar-item"></li>
        </ul>
      </div>
    </nav>
  );
};

export default LeftSidebar;
